* {
  scrollbar-width: thin;
  scrollbar-color: #9b9ea7;
}

* ::-webkit-scrollbar {
  width: 11px;
  height: 12px;
  z-index: 10;
}
*::-webkit-scrollbar-track {
  background: #f2f2f2;
}

*::-webkit-scrollbar-thumb {
  background-color: #9b9ea7;
  border-radius: 6px;
  border: 3px solid #f2f2f2;
}

body {
  font-size: 16px !important;
}

.nav-link {
  /* override Router Link to match mockups */
  display: unset !important;
  padding: 0px !important;
  margin: 0px !important;
  color: #1a1c21 !important;
}
.regular-link {
  font-weight: bolder;
  color: #1a1c21 !important;
}

button {
  outline: none !important;
}
a {
  text-decoration: none;
}

a:focus {
  outline: none !important;
}

/* to use anywhere */
.grid-2x {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(10px, auto);
}

/* to use anywhere */
.grid-3x {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(10px, auto);
}

/* To use anywhere */
.columns-radiogroup > fieldset > div {
  flex-direction: column;
}

/* for ring central widget */
#rc-widget.Adapter_root {
  z-index: 1350 !important;
}

.ck.ck-balloon-panel {
  z-index: 1300 !important;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin: 0 0 1rem 0;
}

.release-panel {
  min-width: 80vw;
}
